import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PdfAnnualShippingHeaderComponent } from './pdf-annual-shipping-header/pdf-annual-shipping-header.component';
import { GeneratePdfService } from 'src/app/services/common/generate-pdf.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MemoriaAnualService } from 'src/app/services/common/memoria-anual.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ActiveContract } from 'src/app/models/active-contracts';

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'april',
  'may',
  'jun',
  'july',
  'aug',
  'sept',
  'oct',
  'now',
  'dec'
]; let labelstop = [

];


@Component({
  selector: 'app-pdf-annual-shipping-micro-sme',
  templateUrl: './pdf-annual-shipping-micro-sme.component.html',
  styleUrls: ['./pdf-annual-shipping-micro-sme.component.scss']
})
export class PdfAnnualShippingMicroSmeComponent implements OnInit,OnChanges {

  @Input() language: string;
  @Input() tableData: any[] = [];
  @Input() contractDate: string;
  @ViewChild(PdfAnnualShippingHeaderComponent) pdfAnnualShippingHeaderComponent: PdfAnnualShippingHeaderComponent;
  dataTableBkp: any;
  public scatterChartType: ChartType = 'line';
  public barChartLabels: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
  locale: any
  chartColor: string = "rgba(90, 155, 213, 1)"
  fullName = '';
  signatureImg: string;
  currentDate = new Date();
  public SelectedReferenceNo: string;
  scatterChartData: ChartDataSets[] = [];
  topContracts: ActiveContract = null;
  
  maxLength: number = 0;
  workCenterData: any;
  constructor(private pdfService: GeneratePdfService,
    private masterDataService: MasterDataService,private memoriaAnualService : MemoriaAnualService) {

  }

  scatterChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        type: 'linear',
        position: 'top',

        ticks: {
          min: 0,
          callback: function (_value, index, _ticks) {
            return labelstop[index];
          },
          beginAtZero: true,
          stepSize: 30,
          fontColor: this.chartColor,
          fontStyle: 'bold'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: false
        },
        ticks: {
          callback: function (_value, index, _ticks) {
            return labels[index];
          },
          fontSize: 10,
          stepSize: 1,
          beginAtZero: true,
          fontColor: this.chartColor,
          fontStyle: 'bold'
        }
      }]
    }
  };

  ngOnChanges(_changes: SimpleChanges): void {
    this.topContracts = JSON.parse(localStorage.getItem('topContracts'));
    this.setTableData();
  }

  getWorkCenterData(wordCenterId) {
    this.memoriaAnualService.getWorkCenterData(wordCenterId).subscribe(res => {      
      if (res) {
          this.workCenterData = res;    
      }
    });
  }

  ngOnInit() {    
    this.getWorkCenterData(this.pdfService.shareData.WorkCentreId);
    let ref = localStorage.getItem("reference");
    this.masterDataService.getActivityPart(ref).subscribe(response => {
      if (response.signatureImage) {
        const src = 'data:image/png;base64,' + response.signatureImage;
        this.signatureImg = src;
      }
    });
    this.fullName = JSON.parse(localStorage.getItem('loggeduser')).fullName;

    this.locale = {
      'es': {
        'activity_plan': 'PROGRAMACIÓN ANUAL DE LA ACTIVIDAD PREVENTIVA',
        'occupational_risk_prevention_manual': 'Manual de prevencion de riesgos laborales',
        'rev': 'Rev.',
        'made_by': 'Realizado por',
        'technician': 'Técnico/a',
        'date': 'Fecha',
        'approved_by': 'Aprobado por',
        'name': 'Nombre',
        'page': 'Página',
        'of': 'de',
        'report_content': 'Contenido del informe',
        'identification_data': 'Datos Identificativos',
        'purpose_of_the_report': 'Objeto del Informe',
        'proposal_for_action': 'Propuesta de actuación',
        'activity': 'Actividad',
        'address': 'Dirección',
        'location': 'Localidad',
        'provincia' : 'Provincia',
        'cp': 'CP',
        'visit': 'Visita',
        'date_of_visit': 'Fecha visita',
        'accompanied_by': 'Acompañaron',
        'report_date': 'Fecha informe',
        'author': 'Autor',
        'object_p1': 'La Ley 31/1995, de 8 de noviembre de 1995, (B.O.E. 10 de noviembre de 1995), en su artículo 31 "Servicios de Prevención" establece, entre otros, que éstos deberán estar en condiciones de proporcionar a la empresa, el asesoramiento y apoyo que precise en función de los tipos de riesgo existentes, así como en lo referente al diseño, aplicación y coordinación de los planes y programas de actuación preventiva.',
        'object_p2': 'Por otro lado, dentro de los planes y programas de actuación preventiva que deben de desarrollar los Servicios de Prevención, el artículo 20 del Reglamento de dichos Servicios, aprobado por el Real Decreto 39/97, de 17 de enero (B.O.E. 31 de enero de 1997), encomienda a los mismos la elaboración de una programación anual, que, en su caso, deberá ser facilitada al Comité de Seguridad y Salud (artículo 39, 2-d de la Ley de Prevención).',
        'object_p3': 'Con el presente documento se pretende definir los objetivos a cubrir con la programación anual y establecer los criterios técnicos a considerar en relación con la misma.',
        'object_p4': 'La programación anual, una vez consensuada servirá como elemento de concreción de la actividad preventiva indicada en el contrato de prestación del servicio de prevención ajeno, durante el periodo indicado en la misma.',
        'object_p5_1': 'En la programación anual,',
        'object_p5_2': 'se definen las acciones preventivas a desarrollar por el servicio de prevención ajeno,',
        'object_p5_3': 'en su caso con la colaboración de la empresa, para cada uno de los principales aspectos exigidos por la normativa de prevención.',
        'object_p6': 'No obstante, durante el periodo especificado en la programación anual esta podrá ser modificada, siempre que concurran una o varias de las siguientes situaciones:',
        'object_p6_li1': 'Que la planificación de la actividad preventiva resultante de la evaluación de los riesgos requiera la inclusión de acciones del servicio de prevención adicionales a las previstas.',
        'object_p6_li2': 'Cuando del resultado del análisis e investigación de los daños a la salud producidos se establezca la necesidad de desarrollar, por el servicio de prevención ajeno, acciones preventivas específicas, no previstas para la eliminación o control de los riesgos que hayan desencadenado los daños a la salud investigados.',
        'object_p6_li3': 'Al producirse modificaciones en las condiciones de trabajo que hagan necesario la adopción de acciones específicas para cumplir con las exigencias que marca la normativa de prevención.',
        'object_p6_li4': 'A tenor de las propuestas que se efectúen por la Autoridad Laboral o en los casos en los que el desarrollo de la actividad aconseje introducir modificaciones, con el fin de conseguir una mejor organización de la acción preventiva o una mayor eficacia de la misma.',
        'object_p6_li5': 'La necesidad de adoptar nuevas actividades o modificar las existentes, a tenor de los imperativos que pudieran determinar la nueva normativa de prevención de riesgos laborales que fuera publicada durante el periodo programado.',
        'object_p7': 'Por último, se recuerda que atendiendo al artículo 36, 2 d) de la Ley de Prevención Riesgos Laborales, el contenido de la programación de los Servicios de Prevención deberá ser puesto a disposición de los delegados de prevención, o de los propios trabajadores, en ausencia de los anteriores. En aquellas empresas que tengan Comité de Seguridad y Salud constituido, dentro de las competencias de este, la Ley de Prevención de Riesgos Laborales en su artículo 39 punto 2, les faculta para conocer e informar acerca de la programación anual de los Servicios de Prevención.',
        'company': 'Empresa',
        'start_date_of_the_current_contact': 'Fecha Inicio del Contrato Actual'
      },
      'ca': {
        'activity_plan': 'PROGRAMACIÓ ANUAL DE L\'ACTIVITAT PREVENTIVA',
        'occupational_risk_prevention_manual': 'Manual de prevencio de riescos laborals',
        'rev': 'Rev.',
        'made_by': 'Realitzat per',
        'technician': 'Tècnic/a',
        'date': 'data',
        'approved_by': 'Aprovat per',
        'name': 'Nom',
        'page': 'Página',
        'of': 'de',
        'report_content': 'Contingut de l\'informe',
        'identification_data': 'Dades identificatives',
        'purpose_of_the_report': 'Objecte de l\'Informe',
        'proposal_for_action': 'Proposta d\'actuació',
        'activity': 'Activitat',
        'address': 'Direcció',
        'location': 'Localitat',
        'provincia': 'Província',
        'cp': 'CP',
        'visit': 'Visita',
        'date_of_visit': 'Data visita',
        'accompanied_by': 'Van acompanyar',
        'report_date': 'Data informe',
        'author': 'Autor',
        'object_p1': 'La Llei 31/1995, de 8 de novembre de 1995, (BOE 10 de novembre de 1995), en el seu article 31 "Serveis de Prevenció" estableix, entre altres, que aquests hauran d\'estar en condicions de proporcionar a l\'empresa, l\'assessorament i suport que precisi en funció dels tipus de risc existents, així com pel que fa al disseny, aplicació i coordinació dels plans i programes d\'actuació preventiva.',
        'object_p2': 'D\'altra banda, dins dels plans i programes d\'actuació preventiva que han de desenvolupar els serveis de prevenció, l\'article 20 del Reglament d\'aquests Serveis, aprovat pel Reial Decret 39/97, de 17 de gener (BOE 31 de gener de 1997), encomana als mateixos l\'elaboració d\'una programació anual, que, si s\'escau, haurà de ser facilitada al Comitè de Seguretat i Salut (article 39, 2-d de la Llei de Prevenció).',
        'object_p3': 'Amb el present document es pretén definir els objectius a cobrir amb la programació anual i establir els criteris tècnics a considerar en relació amb la mateixa.',
        'object_p4': 'La programació anual, un cop consensuada servirà com a element de concreció de l\'activitat preventiva indicada en el contracte de prestació de servei de prevenció aliè, durant el període indicat en la mateixa.',
        'object_p5_1': 'En la programació anual,',
        'object_p5_2': 'es defineixen les accions preventives a desenvolupar pel servei de prevenció aliè,',
        'object_p5_3': 'si escau amb la col·laboració de l\'empresa, per a cada un dels principals aspectes exigits per la normativa de prevenció.',
        'object_p6': 'No obstant això, durant el període especificat en la programació anual aquesta podrà ser modificada, sempre que concorrin una o diverses de les següents situacions:',
        'object_p6_li1': 'Que la planificació de l\'activitat preventiva resultant de l\'avaluació dels riscos requereixi la inclusió d\'accions del servei de prevenció addicionals a les previstes.',
        'object_p6_li2': 'Quan, pel resultat de l\'anàlisi i investigació dels danys a la salut produïts, s\'estableixi la necessitat de desenvolupar, pel servei de prevenció aliè, accions preventives específiques, no previstes per a l\'eliminació o control dels riscos que hagin desencadenat els danys a la salut investigats.',
        'object_p6_li3': 'Al produir-se modificacions en les condicions de treball que facin necessari l\'adopció d\'accions específiques per complir amb les exigències que marca la normativa de prevenció.',
        'object_p6_li4': 'D\'acord amb les propostes que s\'efectuïn per l\'autoritat laboral o en els casos en què el desenvolupament de l\'activitat aconselli introduir modificacions, per tal d\'aconseguir una millor organització de l\'acció preventiva o una major eficàcia de la mateixa.',
        'object_p6_li5': 'La necessitat d\'adoptar noves activitats o modificar les existents, d\'acord amb els imperatius que puguin determinar la nova normativa de prevenció de riscos laborals que fos publicada durant el període programat.',
        'object_p7': 'Finalment, es recorda que atenent a l\'article 36, 2 d) de la Llei de Prevenció Riscos Laborals, el contingut de la programació dels serveis de prevenció ha de ser posat a disposició dels delegats de prevenció, o dels propis treballadors, en absència dels anteriors. En aquelles empreses que tinguin Comitè de Seguretat i Salut constituït, dins de les competències d\'aquest, la Llei de Prevenció de Riscos Laborals en el seu article 39 punt 2, els faculta per a conèixer i informar sobre la programació anual dels serveis de prevenció.',
        'company': 'Empresa',
        'start_date_of_the_current_contact': 'Data d\'Inici del Contracte Actual'
      }
    }
  }

   getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;  
    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }

  setTableData() {
    this.scatterChartData = []
    this.dataTableBkp = this.tableData;
    labelstop = [];

    if (this.dataTableBkp.length > 0) {
      let dataTableSorted = this.dataTableBkp;

      dataTableSorted.sort((a, b) => a.date.localeCompare(b.date) || a.duration - b.duration);
      var sortedMinDate = dataTableSorted[0].date;

      if (sortedMinDate != '') {
        var sortedMaxDate = dataTableSorted[dataTableSorted.length - 1].date;

        const minDate = new Date(sortedMinDate);
        var today = new Date(minDate.getFullYear(), 0, 1);

        const maxDate = new Date(sortedMaxDate);
        maxDate.setDate(maxDate.getDate() + Number(dataTableSorted[dataTableSorted.length - 1].duration) + 30);
        labelstop.push(today.toISOString());
        labelstop.push(maxDate.toISOString());
        var chartFirstDate = new Date();
        for (let i = 0; i < dataTableSorted.length; i++) {

          if (dataTableSorted[i].date != '') {

            labelstop.push(dataTableSorted[i].date);
            if (this.language == 'es') {
              labels[i] = dataTableSorted[i].es;
            }
            if (this.language == 'ca') {
              labels[i] = dataTableSorted[i].ca;
            }

            const selectedActuation = dataTableSorted[i];
            var startDate = new Date(selectedActuation.date)
            const result = (startDate.getMonth() + 1) + "/" + startDate.getDate() + "/" + startDate.getFullYear();
            if(i==0){
              chartFirstDate=new Date(selectedActuation.date)
            }
            const resultToday = (chartFirstDate.getMonth() + 1) + "/" + chartFirstDate.getDate() + "/" + chartFirstDate.getFullYear();

            var diff = this.getDayDiff(new Date(resultToday), new Date(result)) + 1;
            let obj = {
              labels: labels,
              data: [
                { x: diff, y: (dataTableSorted.length - 1) - i },
                { x: diff + Number(dataTableSorted[i].duration), y: (dataTableSorted.length - 1) - i },
              ],
              backgroundColor: this.chartColor,
              borderColor: this.chartColor,
              fill: false,
              borderWidth: 15,              
              pointRadius: 0,
            }
            this.scatterChartData.push(obj);
          }
        }

        labelstop.sort();
        labelstop = labelstop.filter(
          (element, i) => i === labelstop.indexOf(element)
        );
        labelstop = labelstop.map(i => new Date(i)).sort((a, b) => a.getTime() - b.getTime())
        let arry1 = [];

        //New code 10-04-2024
        let chartStart =new Date(dataTableSorted[0].date)
        let chartEnd =new Date(dataTableSorted[dataTableSorted.length - 1].date)

        let sd = new Date(chartStart);
        arry1[0] = chartStart;
        while (sd < chartEnd) {
          let newDate = sd.setMonth(sd.getMonth() + 1);
          arry1.push(new Date(newDate))
        }
        //End code 

        //Old code 10-04-2024
        // let mid = new Date(labelstop[0]);
        // let mad = new Date(labelstop[labelstop.length - 1]);

        // let sd = new Date(mid);
        // arry1[0] = mid;
        // while (sd < mad) {
        //   let newDate = sd.setMonth(sd.getMonth() + 1);
        //   arry1.push(new Date(newDate))
        // }
        //End code 

        let dateArray = [];

        arry1.forEach((value) => {
          let options = { year: 'numeric', month: 'short' };
          let datestr = value.toLocaleDateString('es-ES', options);
          let splittxt = datestr.split(" ")

          let lastDateStri = splittxt[0] + ".-" + splittxt[1].slice(2, 4);
          dateArray.push(lastDateStri)
        });

        labelstop = dateArray;
        this.maxLength = labelstop.length;
      }
    }
  }

  
}
