import { ActivityRoot } from './activity.save.activityroot';

export class MultipleActivitySaveModel {
    activityPartId: number;
    companyId: string;
    companyName: string;
    workCentreId: string;
    workCentreName: string;
    assignedUserId: string;
    assignedUsername: string;
    activityPartDate: any;
    activityPartDateStr: string;
    activityPartTime: string;
    activityPartFinishTime: string;
    activityPartDuration: string;
    activityTypeId: string;
    activityTypeName: string;
    signedUsername: string;
    signedUserDNI: string;
    preventionDelegateName: string;
    preventionDelegateDNI: string;
    recipientEmail: string[];
    userId: string;
    username: string;
    sendEmailToUserOnly: boolean;
    description: string;
    signatureImage: string;
    preventionDelegateSignature: string;
    firmaTecSignature: string;
    emailSubject: string;
    emailBody: string;
    isAccidentOccured: boolean;
    totalAccidents: number;
    visitedAccidents: number;
    investigatedAccidents: number;
    isNewWorkerAdded: boolean;
    isJobInfoGiven: boolean;
    isEPIGiven: boolean;
    isWorkTeamJoined: boolean;
    isPregnantWomenAvl: boolean;
    pregnantWomenRemark: string;
    isMinorsAvl: boolean;
    minorsRemark: string;
    isESensitiveAvl: boolean;
    eSensitiveRemark: string;
    isMedicalCertificateAvl: boolean;
    isCoordinationDone: boolean;
    noOfWorkCenterWorkers: number | null;
    trainingProgramRemark: string;
    activityList: Array<ActivityModel>;
    activityListForClientAPI: Array<ActivityRoot>;
    isValidated: boolean;
    templateLanguage: string;
    currentStepTitle: string;
    noMen: number;
    noWomen: number;
    staffWomen:number;
    staffMen: number;
    ettWomen:number;
    ettMen: number;
    isTrainedRiskPrevention: boolean;
    isUnderMedicalExamination: boolean;
    isOccupationDiseases: boolean;
    menOccupationDiseases: number;
    womenOccupationDiseases: number;
    investigatedOccupationDiseases: number;
    visitedOccupationDiseases: number;
    isChemicalSubstances: boolean;
    isWorkPlaceChanged: boolean;
    islegalProsecution: boolean;
    isRiskNotifications: boolean;
    menAccidents: number;
    womenAccidents: number;
    planActionList: Array<PlanActionModel>;
    title?: string;
    action?: string;
    estimatedDate?: string;
    cost?: string;
    canMitigateRisk?: boolean;
    canEliminateRisk?: boolean;
    actionChekedInVisit?: boolean;
}

export class ActivityModel {
    id: string;
    activityTypeId: string;
    companyId: string;
    workCentreId: string;
    assignedUserId: string;
    duration: string;
    description: string;
    statusTypeId: string;
    userId: string;
    activityDate: any;
    activityTime: string;
    isDeleted = false;
    entryDate: Date;
    activityTypeName: string;
    statusTypeName: string;
}

export class PlanActionModel {
    title: string = "";
    action: string = null;
    estimatedDate: string = "";
    description: string = "";
    cost: string = "";
    responsible: string ="";
    canMitigateRisk: boolean = null;
    canEliminateRisk: boolean = null;
    actionChekedInVisit: boolean = null;
    isMarked: boolean = true;
}
