import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from 'angular2-wizard';
import { NgxPaginationConfig } from 'src/app/models/ngx-pagination-config';
import { ActivityService } from 'src/app/services/activity.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { MasterDataService } from 'src/app/services/master-data.service';
import { CompanyData, WorkCenterData } from 'src/app/models/PlanDePrevWizardModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-prevention-plan-popup',
  templateUrl: './prevention-plan-popup.component.html',
  styleUrls: ['./prevention-plan-popup.component.scss']
})
export class PreventionPlanPopupComponent implements OnInit {
  @ViewChild("wizardComponent", null) wizardComponent: WizardComponent;

  @Input() public companyData: any;
  workCenterList: WorkCenterData[];

  languageList = [{ value: 'es', label: 'Español' },
  { value: 'ca', label: 'Catalán' },
  { value: 'en', label: 'English' }];

  organizationChatrs: any = [];
  organizationPaginationConfig = new NgxPaginationConfig();
  isCommittee: boolean = false;
  isDelegados: boolean = false;
  itemsPerPage: number = 5;
  language_id: string = 'es';

  FomularioForm = new FormGroup({
    direction: new FormControl({ disabled: true, value: '' }, [Validators.required]),
    population: new FormControl({ disabled: true, value: '' }, [Validators.required]),
    provincia: new FormControl({ disabled: true, value: '' }, Validators.required),
    companyActivity: new FormControl({ disabled: true, value: '' }),
    CIF: new FormControl({ disabled: true, value: '' }, Validators.required),
    CP: new FormControl({ disabled: true, value: '' }),
    CNAENum: new FormControl(null, [Validators.required]),
    CNAEDec: new FormControl(null, [Validators.required]),
    centros: new FormControl({ disabled: true, value: '' }),
    trabajadores: new FormControl({ disabled: true, value: '' }),
  });

  // WorkCenterForm = new FormGroup({
  //   direction: new FormControl({ disabled: true, value: '' }, [Validators.required]),
  //   population: new FormControl({ disabled: true, value: '' }, [Validators.required]),
  //   description: new FormControl('', Validators.required),
  //   CP: new FormControl({ disabled: true, value: '' }),
  //   trabajadores: new FormControl({ disabled: true, value: '' }),
  // });

  OrganizationForm = new FormGroup({
    directorGeneral: new FormControl('', [Validators.required]),
    interlocutor: new FormControl('', [Validators.required]),
    isDelegados: new FormControl(false),
    delegados: new FormControl({value: '' ,disabled: true}),
    isCommittee: new FormControl(false),
    committee: new FormControl({value: '' ,disabled: true}),
  });

  EmailDownloadForm = new FormGroup({
    EmailSubject: new FormControl('', [Validators.required]),
    EmailBody: new FormControl(null , [Validators.required]),
    ContactEmail: new FormControl(null , [Validators.required]),
  });

  OrgTypeForm = new FormGroup({
    Unidad: new FormControl('', [Validators.required]),
    Responsable: new FormControl('', [Validators.required]),
    Trabajadores: new FormControl('', [Validators.required]),
  });

  constructor(public modal: NgbActiveModal,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private errorHandler: ErrorHandlerService,
    public masterDataServices: MasterDataService,
    public activityService: ActivityService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.language_id = 'es';
    this.organizationPaginationConfig.itemsPerPage = this.itemsPerPage;
    this.organizationPaginationConfig.totalItems = this.organizationChatrs.length;
    this.organizationPaginationConfig.currentPage = 1;
    this.setEmailContent();
    this.getPlanWizardDetails(this.companyData.CompanyId);
    this.changeWizardText();
  }

  setEmailContent() {
    this.EmailDownloadForm.patchValue({
      EmailSubject: "Documento Plan de Prevención",
      EmailBody: `Apreciado cliente, \n\n` +
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu nunc at nisl pellentesque vehicula sed sed leo. ` +
      `Praesent ut quam nec nisl tincidunt aliquet. Curabitur dignissim nulla purus, a semper odio lacinia ut. \n\n` +
      `Atentamente,`
    });
  }

  getPlanWizardDetails(companyId: string) {
    this.masterDataServices.getPlanDePrevencionDetails(companyId).subscribe(result => {      
      if (result != null) {
        this.workCenterList = result.workCenter;
        this.setCompanyValues(result.company);       
      }
    });
  }

  setCompanyValues(companyData: CompanyData) {
    this.FomularioForm.controls.direction.setValue(companyData.direccion);
    this.FomularioForm.controls.population.setValue(companyData.poblacion);
    this.FomularioForm.controls.provincia.setValue(companyData.provincia);
    this.FomularioForm.controls.CIF.setValue(companyData.cif);
    this.FomularioForm.controls.companyActivity.setValue(companyData.activity);
    this.FomularioForm.controls.CNAENum.setValue(companyData.cnaE_Num);
    this.FomularioForm.controls.CNAEDec.setValue(companyData.cnaE_Desc);
    this.FomularioForm.controls.CP.setValue(companyData.codigoPostal);
    this.FomularioForm.controls.trabajadores.setValue(companyData.numberOfEmployees);
    this.FomularioForm.controls.centros.setValue(this.workCenterList.length);
  }

  changeWizardText(_isFirma = false): void {
    const modalElement = document.getElementById('prevention-plan');
    const footer = modalElement.getElementsByClassName('card-footer')[0];
    const footerBtns = footer.getElementsByClassName('btn');

    for (let index = 0; index < footerBtns.length; index++) {
      footerBtns[index].classList.add('wizard-buttons');
      if (footerBtns[index].innerHTML === 'Previous') {
        footerBtns[index].innerHTML = 'Anterior';
      } else if (footerBtns[index].innerHTML === 'Next') {
        footerBtns[index].innerHTML = 'Siguiente';
      }  else if (footerBtns[index].innerHTML === "Done") {
        const doneBtn = footerBtns[index] as HTMLElement | null;
        if (doneBtn != null) {
          doneBtn.style.display = "none";
        }
      }
    }
  }

  onDeleteOrganization(data: any) {    
    const index = this.organizationChatrs.indexOf(data);
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.confirmationMessage = "quieres borrar este registro ?";
    modalRef.result.then( close => {
      if (close == true) {        
        this.organizationChatrs.splice(index, 1 );
        this.organizationPaginationConfig.totalItems = this.organizationChatrs.length;
      }      
    });
  }

  addOrganization() {
    this.organizationChatrs.push({
      Unidad : " " , 
      Responsable : ' ' , 
      Trabajadores : ' '
    });
    this.organizationPaginationConfig.totalItems = this.organizationChatrs.length;
    let currentpage = this.calculatePages(this.organizationChatrs.length);
    this.organizationPaginationConfig.currentPage = currentpage;
  }

  public pageChanged(pageOfItems: number) {
    this.organizationPaginationConfig.currentPage = pageOfItems;
  }

  onComiteClick(event) {
    this.isCommittee = event.target.checked;
    if (event.target.checked == true) {
      this.isDelegados = true;
      this.OrganizationForm.controls.delegados.enable();
      this.OrganizationForm.controls.committee.enable();
    } else {
      this.isDelegados = false;
      this.OrganizationForm.controls.delegados.disable();
      this.OrganizationForm.controls.committee.disable();
      this.OrganizationForm.controls.delegados.setValue("");
      this.OrganizationForm.controls.committee.setValue("");
    }
  }

  calculatePages(rows: number) {
    const pages = rows / this.itemsPerPage;
    return Math.floor(pages) + (pages % 1 !== 0 ? 1 : 0);
  }

  onDelegadosClick(event) {
    this.isDelegados = event.target.checked;
    if (event.target.checked == true) {
      this.isDelegados = true;
      this.OrganizationForm.controls.delegados.enable();
    } else {
      this.isDelegados = false;
      this.OrganizationForm.controls.delegados.disable();
      this.OrganizationForm.controls.delegados.setValue("");
    }
  }

  DownloadPDFDocument(): void {
    this.spinner.show();   
    var requestModel = this.setPlanDePrevDetails();

    this.masterDataServices.exportPlanDePrevePDF(requestModel).subscribe(response => {
      const linkSource = 'data:application/pdf;base64,' + response.base64String + '\n';
      const downloadLink = document.createElement('a');
      const fileName = response.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.spinner.hide();
    }, error => {
      this.toastrService.error('No se pudo descargar el archivo adjunto', 'Error');
      this.errorHandler.handle(error);
      this.spinner.hide();
    });
  }

  DownloadWordDocument(): void {
    this.spinner.show();   
    var requestModel = this.setPlanDePrevDetails();

    this.masterDataServices.exportPlanDePreveWord(requestModel).subscribe(response => {
      const linkSource = 'data:application/pdf;base64,' + response.base64String + '\n';
      const downloadLink = document.createElement('a');
      const fileName = response.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.spinner.hide();
    }, error => {
      this.toastrService.error('No se pudo descargar el archivo adjunto', 'Error');
      this.errorHandler.handle(error);
      this.spinner.hide();
    });
  }

  setPlanDePrevDetails() {
    let emailAddress = this.EmailDownloadForm.controls.ContactEmail.value;
    let recipientEmail= [];
    if(emailAddress != null) {
      emailAddress.forEach(item => {
        recipientEmail.push(item.value);
      });
    }   

    return  {
      companyName: this.companyData.CompanyName,
      cif: this.FomularioForm.controls.CIF.value,
      provincia: this.FomularioForm.controls.provincia.value,
      cnaE_Num: this.FomularioForm.controls.CNAENum.value,
      cnaE_Desc: this.FomularioForm.controls.CNAEDec.value,
      activity: this.FomularioForm.controls.companyActivity.value,
      direccion: this.FomularioForm.controls.direction.value,
      poblacion: this.FomularioForm.controls.population.value,
      codigoPostal: this.FomularioForm.controls.CP.value,
      numberOfEmployees: Number(this.FomularioForm.controls.trabajadores.value),
      workCenters: this.workCenterList,
      organizations: {
        directorGeneral: this.OrganizationForm.controls.directorGeneral.value,
        interlocutor: this.OrganizationForm.controls.interlocutor.value,
        isDelegados: this.isDelegados ,
        isCommittee: this.isCommittee ,
        delegados: this.OrganizationForm.controls.delegados.value,
        committee: this.OrganizationForm.controls.committee.value,
        organigramaCharts: this.organizationChatrs},
      emailSubject: this.EmailDownloadForm.controls.EmailSubject.value,
      emailBody: this.EmailDownloadForm.controls.EmailBody.value,
      recipientEmail: recipientEmail,
    };
    
  }

  public sendPlanDePrevensionEmail(): void {
    if (this.EmailDownloadForm.valid) {
      this.spinner.show();
      setTimeout(() => {
        let requestData = this.setPlanDePrevDetails();
        this.masterDataServices.sendPlanDePrevencionEmail(requestData).subscribe(
          (response) => {
            this.spinner.hide();
            this.modal.close();
            this.toastrService.success(
              "El correo electrónico se ha enviado correctamente a las direcciones de correo electrónico proporcionadas.",
              "Éxito"
            );
          },
          (error) => {
            this.spinner.hide();
            this.errorHandler.handle(error);
            this.toastrService.error(
              "No se pudo enviar el correo electrónico",
              "Error"
            );
          }
        );
      }, 8000);
    } else {
      this.toastrService.warning(
        "Ingrese detalles válidos para continuar",
        "Advertencia"
      );
    }
  }

}
