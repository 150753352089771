import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddEditActivityComponent } from '../layout/shared/popups/add-edit-activity/add-edit-activity.component';
import { ActivityService } from './activity.service';
import { ErrorHandlerService } from './error-handler.service';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityCalendarListService {

  modalOptions: NgbModalOptions;
  public isCalendar = true;
  public activityAddEditCompleted = new EventEmitter();

  constructor(
    private activityService: ActivityService,
    private errorHandler: ErrorHandlerService,
    private modalService: NgbModal,
    private filterService: FilterService) {
    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      size: 'lg',
      centered: true
    };
  }

  public async addEditActivity(model: any, isEdit: boolean) {
    if (isEdit) {
      const activityId = this.isCalendar ? model.event.id : model.id;
      this.openActivityEditPopup(activityId);
    } else {
      this.openActivityAddPopup(model);
    }
  }

  public openActivityAddPopup(model: any) {
    const modalRef = this.modalService.open(AddEditActivityComponent, this.modalOptions);
    let activityTime = model.date;
    if (model.view.type === 'dayGridMonth') {
      activityTime = new Date();
    }
    modalRef.componentInstance.addEditActivityForm.patchValue({
      ActivityDate: model.date,
      ActivityTime: activityTime,
      StatusTypeId: null
    });

    modalRef.result.then((_close) => {
      this.filterService.setFilterValuesForAPI();
      this.activityService.filterValues = this.filterService.filterObject;
      if (this.isCalendar) {
        this.activityService.fetchActivityList();
      }
    });
  }

  public openActivityEditPopup(activityId: string): void {
    this.activityService.getActivity(activityId).then(response => {
      
      const sActivity = this.activityService.mapActivityResponseToActivityModel(response);

      const modalRef = this.modalService.open(AddEditActivityComponent, this.modalOptions);
      modalRef.componentInstance.isEdit = true;

      if (sActivity.activityTypeName.toLowerCase().trim().includes('visita')) {
        modalRef.componentInstance.isVisit = true;
      } else if (sActivity.activityTypeName.toLowerCase().trim().includes('memoria anual')) {
        modalRef.componentInstance.isAnnual = true;
      }
      const activityDate = new Date(sActivity.activityDate);
      const [hours, minutes, seconds] = String(sActivity.activityTime).split(':');
      const activityTime = new Date(activityDate.getFullYear(), activityDate.getMonth(),
        activityDate.getDate(), +hours, Number(minutes), Number(seconds));

      const [hoursE, minutesE, secondsE] = String(sActivity.activityFinishTime).split(':');
      const activityEndTime = new Date(activityDate.getFullYear(), activityDate.getMonth(),
        activityDate.getDate(), +hoursE, Number(minutesE), Number(secondsE));

      modalRef.componentInstance.addEditActivityForm.patchValue({
        Id: sActivity.id,
        ActivityTypeId: sActivity.activityTypeId,
        ActivtyTypeName: sActivity.activityTypeName,
        CompanyId: sActivity.companyId,
        CompanyName: sActivity.companyName,
        WorkCentreId: sActivity.workCentreId,
        WorkCentreName: sActivity.workCentreName,
        AssignedUserId: sActivity.assignedUserId,
        AssignedUsername: sActivity.assignedUserName,
        StatusTypeId: sActivity.statusTypeId,
        StatusTypeName: sActivity.statusTypeName,
        ActivityDate: sActivity.activityDate,
        ActivityStartTime: activityTime,
        ActivityEndTime: activityEndTime,
        Duration: sActivity.duration,
        Description: sActivity.description
      });

      modalRef.result.then((close) => {
        this.filterService.setFilterValuesForView();
        this.activityService.filterValues = this.filterService.filterObject;

        this.activityAddEditCompleted.emit(true);
        if (this.isCalendar) {
          this.activityService.fetchActivityList();
        }

      }, (_dismiss) => { });
      this.activityService.companyId = sActivity.companyId;
    }, error => {
      this.errorHandler.handle(error);
    });
  }
}
