import { Injectable } from '@angular/core';
import { Activity } from 'src/app/models/activity.model';
import { ApiService } from './api.service';
import { MultipleActivitySaveModel } from 'src/app/models/multiple.activity.save.model';
import { DropdownService } from 'src/app/services/dropdown.service';
import { PlanAction } from '../models/planAction';

@Injectable({
  providedIn: 'root'
})
export class MultipleActivityService {

  constructor(private apiService: ApiService, private dropdownService: DropdownService) {
  }

  saveModel: MultipleActivitySaveModel = new MultipleActivitySaveModel();

  // Activity Type, Description List
  selectedActivity: Array<Activity> = [];
  selectedPlanAction: Array<PlanAction> = [];

  public getActivityWithCommonValues(): Activity {
    const activity = new Activity();
    activity.companyId = String(this.saveModel.companyId);
    activity.companyName = this.saveModel.companyName;
    activity.workCentreId = String(this.saveModel.workCentreId);
    activity.workCentreName = this.saveModel.workCentreName;
    activity.assignedUserId = String(this.saveModel.assignedUserId);
    activity.assignedUserName = this.saveModel.assignedUsername;
    activity.activityDate = this.saveModel.activityPartDate; // new Date(this.saveModel.activityPartDate);
    activity.activityTime = this.saveModel.activityPartTime;
    activity.userId = JSON.parse(localStorage.getItem('loggeduser')).id;
    return activity;
  }

  public refreshActivityList() {
    this.selectedActivity.forEach(activity => {
      activity.companyId = String(this.saveModel.companyId);
      activity.companyName = this.saveModel.companyName;
      activity.workCentreId = String(this.saveModel.workCentreId);
      activity.workCentreName = this.saveModel.workCentreName;
      activity.assignedUserId = String(this.saveModel.assignedUserId);
      activity.assignedUserName = this.saveModel.assignedUsername;
      activity.activityDate = this.saveModel.activityPartDate; // new Date(this.saveModel.activityPartDate);
      activity.activityTime = this.saveModel.activityPartTime;
      activity.statusTypeId = '4';
    });
  }  

  public getPlanActionWithCommonValues(): PlanAction {
    const planAction = new PlanAction();
    planAction.title = this.saveModel.title;
    planAction.action = this.saveModel.action;
    planAction.description = this.saveModel.description;
    planAction.estimatedDate = this.saveModel.estimatedDate;
    planAction.cost = this.saveModel.cost;
    planAction.canEliminateRisk = this.saveModel.canEliminateRisk;
    planAction.canMitigateRisk = this.saveModel.canEliminateRisk;
    planAction.actionChekedInVisit = this.saveModel.actionChekedInVisit;
    return planAction;
  }

  public refreshPlanActionList() {
    this.selectedPlanAction.forEach(activity => {
      activity.title = this.saveModel.title;
      activity.action = this.saveModel.action;
      activity.estimatedDate = this.saveModel.estimatedDate;
      activity.description = this.saveModel.description;
      activity.cost = this.saveModel.cost;
      activity.canEliminateRisk = this.saveModel.canEliminateRisk;
      activity.canMitigateRisk = this.saveModel.canEliminateRisk;
      activity.actionChekedInVisit = this.saveModel.actionChekedInVisit; 
    });
  }
}
