import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { Activity } from '../models/activity.model';
import { ActuacionItem } from '../models/actuacion-item';
import { ActuacionListResponse } from '../models/actuacion-list-response';
import { ErrorHandlerService } from './error-handler.service';
import { ActivityCalendarEvent } from 'src/app/models/activity-calendar-event.model';
import { environment } from 'src/environments/environment'
import { Utils } from '../models/utils';
import { HttpClient } from '@angular/common/http';
import { ActivityRoot } from '../models/activity.save.activityroot';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioUpsert } from '../models/activity.save.usuarioupsert';
import { EspecialidadTecnica } from '../models/activity.save.especialidadtecnica';
import { SubSink } from 'subsink';
import { DropdownService } from './dropdown.service';
import { PaginationRowsPerPage } from '../models/pagination-rows-per-page-model';
import { SelectOptions } from '../models/select-options.model';
import { PaginationRange } from '../models/pagination-range-model';
import { ActivityFilterModel } from '../models/activity-filter';
import { NgxPaginationConfig } from '../models/ngx-pagination-config';
import { ActivityStatusService } from './common/activity-status.service';
import { FilterService } from './filter.service';


@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  // This list is for calendar
  public activityList: Array<ActivityCalendarEvent>;
  // This list is for List View
  public listViewArray: Array<ActuacionItem>;

  public selectedDate = new Subject<Date>();
  public activityCalendarRefreshSubject = new Subject();
  public pageRangeSubject = new Subject();
  public totalActionCountSubject = new Subject();
  public selectedPageValueSubject = new Subject();
  public notificationRefeshSubject = new Subject();
  public filterValues: ActivityFilterModel;
  error: string;
  response: ActuacionListResponse;
  private activityRoot: ActivityRoot;
  private clientReqIPAddress = '';
  private subs = new SubSink();
  public listViewPaginationRanges$: SelectOptions[];
  public listViewPaginationRowsPerPage$: SelectOptions[];
  public totalRecordCount = 0;
  public pageCount = 0;
  public pageNumberList: any[] = [];
  companyId:string;
  // For list view pagination
  listViewPaginationConfig = new NgxPaginationConfig();

  constructor(
    private apiService: ApiService, private errorHandler: ErrorHandlerService,
    private httpClient: HttpClient, private spinner: NgxSpinnerService, private dropdownService: DropdownService, private activtiyStatus : ActivityStatusService, public filterService: FilterService) {
    this.apiService.baseUrl = environment.gesemeApiUrl;
    this.subs.add(
      this.dropdownService.getClientReqIPAddress().subscribe(response => {
        this.clientReqIPAddress = response.clientReqIPAddress;
      }, error => {
        this.errorHandler.handle(error);
      }),
    );
  }

  mapActivityToCalendarEvent(activities: Array<ActuacionItem>, paginationRangeArr: any[] = []) {
    const tempActivityList = [];
    activities.forEach(activity => {
      // Get the date in the format MM/dd/yyyy
      activity.fecha = activity.fecha.substr(4, 2) + '/' + activity.fecha.substr(6, 2) + '/' + activity.fecha.substr(0, 4);
      const activityDate = new Date(activity.fecha);
      // Get the time in the format HH:mm:ss
      activity.hora_inicio = activity.hora_inicio.substr(0, 2) + ':' + activity.hora_inicio.substr(2, 2)
        + ':' + activity.hora_inicio.substr(4, 2);
      const finishTimeParam = activity.hora_fin == null ? null : new Date(activity.hora_fin);
      const activityFinishTime = this.getActivityFinishTime(new Date(activity.fecha), finishTimeParam, null);
      activity.hora_fin = activityFinishTime;

      // Dates should be in the following format -- 2020-01-09T12:30:00
      const activityStartString = activityDate.getFullYear() + '-'
        + ('0' + (activityDate.getMonth() + 1)).slice(-2) + '-'
        + ('0' + (activityDate.getDate())).slice(-2)
        + 'T' + String(activity.hora_inicio);

      const activityEndString = activityDate.getFullYear() + '-'
        + ('0' + (activityDate.getMonth() + 1)).slice(-2) + '-'
        + ('0' + (activityDate.getDate())).slice(-2)
        + 'T' + String(activity.hora_inicio);


      const title = activity.empresa_nombre + ' - ' + activity.tipo_evento_especialidad_castellano;

      const activityItem = {
        id: activity.id,
        title: title,
        start: activityStartString,
        end: activityEndString,
        allDay: false,
        color: this.getColorByActivityStatus(activity.evento_estado.trim()),
        //classNames: [eventClassName]
      };
      tempActivityList.push(activityItem);
    });

    this.activityList = tempActivityList;
    this.listViewArray = activities;
    this.listViewPaginationRanges$ = paginationRangeArr;
  }

  getActivityFinishTime(startTime: Date, finishTime: Date, duration: number): string {

    // NOTE: Get finish time in HHmmss format
    let finishTimeStr = '';
    // For adding/updating actuacion
    if (duration != null && finishTime == null) {
      const actualFinishTime = new Date(startTime.getTime() + duration * 60000); // multiply by 600000 to convert to milliseconds
      finishTimeStr = ('0' + actualFinishTime.getHours().toString()).slice(-2) +
        ('0' + actualFinishTime.getMinutes().toString()).slice(-2) + ('0' + actualFinishTime.getSeconds().toString()).slice(-2);
    } else {
      if (finishTime != null) {
        finishTimeStr = ('0' + finishTime.getHours().toString()).slice(-2) +
          ('0' + finishTime.getMinutes().toString()).slice(-2) + ('0' + finishTime.getSeconds().toString()).slice(-2);
      } else {
        const timeDiff = finishTime.getTime() - startTime.getTime();
        const actualFinishTime = new Date(startTime.getTime() + timeDiff);
        finishTimeStr = ('0' + actualFinishTime.getHours().toString()).slice(-2) +
          ('0' + actualFinishTime.getMinutes().toString()).slice(-2) + ('0' + actualFinishTime.getSeconds().toString()).slice(-2);
      }
    }
    return finishTimeStr;
  }

  getActivityDuration(startTime: Date, finishTime: Date): number {
    let durationVal = 15;
    if (startTime != null && finishTime != null) {
      durationVal = Math.floor((finishTime.getTime() - startTime.getTime()) / 60000); // Divide by 60000 to get value in minutes
    }
    return durationVal;
  }

  getColorByActivityStatus(status_id: String) {
    let data = JSON.parse(localStorage.getItem("colorList"));    
    let activityStatus = data?.find(x=>x.id == status_id);
    if(activityStatus!= null && activityStatus != undefined){
      return activityStatus.numcolor;
    }
    
  }
 

  mapActivityResponseToActivityModel(response: any): Activity {
    let startTimeStr: string = null, actualStartTime: Date = null, finishTimeStr: string = null, actualFinishTime: Date = null;
    const result = response.data;
    const currentDate = new Date();
    let [hours, minutes, seconds] = ['', '', ''];
    if (result.hora_inicio !== null) {
      // Get the time in the format HH:mm:ss //response.activityTime;
      startTimeStr = result.hora_inicio.substr(0, 2) + ':' + result.hora_inicio.substr(2, 2) +
        ':' + result.hora_inicio.substr(4, 2);
      [hours, minutes, seconds] = startTimeStr.split(':');
    } else if (result.especialidadTecnica.hora_inicio !== null) {
      // Get the time in the format HH:mm:ss //response.activityTime;
      startTimeStr = result.especialidadTecnica.hora_inicio.substr(0, 2) + ':' +
        result.especialidadTecnica.hora_inicio.substr(2, 2) + ':' + result.especialidadTecnica.hora_inicio.substr(4, 2);
      [hours, minutes, seconds] = startTimeStr.split(':');
    }

    actualStartTime = new Date(currentDate.getFullYear(), currentDate.getMonth(),
      currentDate.getDate(), +hours, Number(minutes), Number(seconds));

    if (result.hora_fin !== null) {
      // Get the time in the format HH:mm:ss //response.activityTime;
      finishTimeStr = result.hora_fin.substr(0, 2) + ':' + result.hora_fin.substr(2, 2) + ':' + result.hora_fin.substr(4, 2);
      [hours, minutes, seconds] = finishTimeStr.split(':');
    } else if (result.especialidadTecnica.hora_fin !== null) {
      // Get the time in the format HH:mm:ss //response.activityTime;
      finishTimeStr = result.especialidadTecnica.hora_fin.substr(0, 2) + ':' +
        result.especialidadTecnica.hora_fin.substr(2, 2) + ":" + result.especialidadTecnica.hora_fin.substr(4, 2);
      [hours, minutes, seconds] = finishTimeStr.split(':');
    }

    actualFinishTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), +hours, Number(minutes), Number(seconds));

    let activity = new Activity();
    activity = {
      id: result.id,
      alias: result.alias,
      activityTypeId: String(result.especialidadTecnica.tipo_especialidad),
      activityTypeName: result.especialidadTecnica.tipo_especialidad_castellano,
      companyId: String(result.especialidadTecnica.empresa_id),
      companyName: result.especialidadTecnica.empresa_nombre,
      workCentreId: String(result.especialidadTecnica.empresa_centro_id),
      workCentreName: result.especialidadTecnica.empresa_centro_nombre,
      assignedUserId: String(result.user_assigned),
      assignedUserName: `${result.user_assigned_nombre} ${result.user_assigned_apellidos}`,
      activityDate: new Date(result.fecha.substr(4, 2) + "/" + result.fecha.substr(6, 2) + "/" + result.fecha.substr(0, 4)),// Get the date in the format mm/dd/yyyy //response.activityDate,
      activityTime: startTimeStr,
      activityFinishTime: finishTimeStr,
      duration: this.getActivityDuration(actualStartTime, actualFinishTime).toString(),
      description: result.descripcion,
      statusTypeId: String(result.especialidadTecnica.evento_estado),
      statusTypeName: result.especialidadTecnica.evento_estado_alias,
      userId: result.user_insert,
      isDeleted: response.eliminado,
      username: '',
      entryDate: new Date()
    };
    return activity;
  }

  mapActivityModelToActivityRoot(activity: Activity): ActivityRoot {

    let actualStartTime: Date = null, actualFinishTime: Date = null;
    const [actDay, actMonth, actYear] = activity.activityDate.split("/");
    let actualActivityDate = new Date(Number(actYear), Number(actMonth), Number(actDay));
    const activityDateStr = actYear + ('0' + actMonth).slice(-2) + ('0' + actDay).slice(-2); // Get activity date in YYYYMMdd format
    let [hours, minutes, seconds] = activity.activityTime.split(':');
    let activityTimeStr = ('0' + hours).slice(-2) + ('0' + minutes).slice(-2) + ':00';

    if (activity.activityTime !== null) {
      actualStartTime = new Date(Number(actYear), Number(actMonth), Number(actDay), +hours, Number(minutes), Number(seconds));
    }

    if (activity.activityFinishTime !== null) {
      let [hours, minutes, seconds] = activity.activityFinishTime.split(':');
      actualFinishTime = new Date(Number(actYear), Number(actMonth), Number(actDay), +hours, Number(minutes), Number(seconds));
    }

    this.activityRoot = new ActivityRoot();
    var mainDetails = new EspecialidadTecnica();
    var dataInsertUser = new UsuarioUpsert();
    mainDetails = {
      tipo_especialidad: activity.activityTypeId,
      evento_estado: activity.statusTypeId,
      tiempo_estimado: 0,
      hora_inicio: activity.activityTime.replace(":", "").replace(":", ""),
      hora_fin: this.getActivityFinishTime(actualStartTime, actualFinishTime, Number(activity.duration)),
      empresa_id: activity.companyId,
      empresa_centro_id: activity.workCentreId
    };

    dataInsertUser = {
      fechaHora: actualActivityDate,
      ipOrigen: this.clientReqIPAddress,
      objectId2: "" // TODO: Not yet clarified.
    };

    this.activityRoot = {
      id: activity.id == "" ? null : activity.id,
      alias: activity.alias,
      descripcion: activity.description,
      fecha: activityDateStr,
      hora: activityTimeStr.replace(":", "").replace(":", ""), // Replace ':' with empty string =>should be in HHmmss format 
      user_assigned: activity.assignedUserId,
      especialidadTecnica: mainDetails,
      usuario_upsert: dataInsertUser
    };

    return this.activityRoot;
  }

  mapExistingActivityToActivityModel(response: ActuacionItem): Activity {

    let startTimeStr: string = null, actualStartTime: Date = null, finishTimeStr: string = null, actualFinishTime: Date = null;

    if (response.hora_inicio !== null) {
      startTimeStr = response.hora_inicio.substr(0, 2) + ":" + response.hora_inicio.substr(2, 2) + ":" + response.hora_inicio.substr(4, 2);//Get the time in the format HH:mm:ss     
      let [hours, minutes, seconds] = startTimeStr.split(':');;
      actualStartTime = new Date();
      actualStartTime.setHours(+hours);
      actualStartTime.setMinutes(Number(minutes));
      actualStartTime.setSeconds(Number(seconds));
    }

    if (response.hora_fin !== null) {
      finishTimeStr = response.hora_fin.substr(0, 2) + ":" + response.hora_fin.substr(2, 2) + ":" + response.hora_fin.substr(4, 2);//Get the time in the format HH:mm:ss //response.activityTime;     
      let [hours, minutes, seconds] = finishTimeStr.split(':');;
      actualFinishTime = new Date();
      actualFinishTime.setHours(+hours);
      actualFinishTime.setMinutes(Number(minutes));
      actualFinishTime.setSeconds(Number(seconds));
    }

    let activity = new Activity();
    let [actMonth, actDate, actYear] = response.fecha.split("/");
    activity = {
      id: response.id,
      alias: response.alias,
      activityTypeId: String(response.tipo_evento_especialidad),
      activityTypeName: response.tipo_evento_especialidad_castellano,
      companyId: String(response.empresa_id),
      companyName: response.empresa_nombre,
      workCentreId: String(response.empresa_centro_id),
      workCentreName: response.empresa_centro_nombre,
      assignedUserId: String(response.usuario_asignado),
      assignedUserName: `${response.usuario_asignado_nombre} ${response.usuario_asignado_apellidos}`,
      activityDate: new Date(parseInt(actYear), parseInt(actMonth) - 1, parseInt(actDate)), //reduce 1 from the month to get the actual month
      activityTime: startTimeStr,
      activityFinishTime: finishTimeStr,
      duration: this.getActivityDuration(actualStartTime, actualFinishTime).toString(),
      description: response.descripcion,
      statusTypeId: String(response.evento_estado),
      statusTypeName: response.evento_estado_alias,
      userId: JSON.parse(localStorage.getItem("loggeduser")).id,
      isDeleted: false,
      username: "",
      entryDate: new Date()
    };

    return activity;
  }

  hasFilterValues(obj: any): boolean {
    for (const key in obj) {
      if (obj[key] === null) {
        return false;
      }
    }
    return true;
  }

  fetchActivityList() {
    this.spinner.show();
    let actualFilterObj = new ActivityFilterModel();
    actualFilterObj.tipo_consulta = this.filterValues.tipo_consulta;
    actualFilterObj.id_tecnico = this.filterValues.id_tecnico;
    actualFilterObj.id_tecnico_selected =this.filterService.filterObject.id_tecnico_selected;
    actualFilterObj.page_number = this.filterValues.page_number;
    actualFilterObj.page_size = this.filterValues.page_size;
    actualFilterObj.fecha_desde = this.filterValues.fecha_desde;
    actualFilterObj.fecha_hasta = this.filterValues.fecha_hasta;
    actualFilterObj.id_actuacionestado = this.filterValues.id_actuacionestado == '0' ? null : this.filterValues.id_actuacionestado;
    actualFilterObj.id_actuaciontipo = this.filterValues.id_actuaciontipo == '0' ? null : this.filterValues.id_actuaciontipo;
    actualFilterObj.id_empresa = this.filterValues.id_empresa == '0' ? null : this.filterValues.id_empresa;
    actualFilterObj.id_centro = this.filterValues.id_centro == '0' ? null : this.filterValues.id_centro;


    this.getFilteredActivities(actualFilterObj).subscribe(response => {
      this.spinner.hide();
      this.response = response;
      if (this.response != null) {
        if (this.response.webError != null) {
          this.error = this.response.webError.messageToUser;
          this.spinner.hide();
          this.errorHandler.handle(this.response.webError.messageToUser, true);
        } else {
          this.totalRecordCount = response.links.rowcount;
          this.listViewPaginationConfig.totalItems = this.totalRecordCount;
          this.pageCount = Math.floor(response.links.rowcount / response.data.length);
          if (this.pageCount > 0) {
            this.pageNumberList = Array.from(Array(this.pageCount).keys()).map(i => i + 1);
          } 
          this.mapActivityToCalendarEvent(response.data);
          this.activityCalendarRefreshSubject.next('');
        }
      }
    }, error => {
      this.spinner.hide();
      this.error = Utils.formatError(error);
    });
  }

  async getActivity(activityId: string): Promise<ActuacionListResponse> {
    return this.apiService.get('erp/actuacion/' + activityId).toPromise();
  }

  // NOTE: Activity list is fetched from the client API
  getFilteredActivities(filterParameters: any, isForFilter: boolean = true): Observable<ActuacionListResponse> {
    if (isForFilter) {
      this.filterValues = filterParameters;
    }
    return this.httpClient.post<ActuacionListResponse>(this.apiService.baseUrl + "erp/actuaciones", filterParameters);
  }

  async saveActivity(activity: ActivityRoot): Promise<any> {
    return this.httpClient.post<any>(this.apiService.baseUrl + "erp/actuacion", activity).toPromise();
  }

  deleteActivity(activity: any): Observable<any> {
    return this.httpClient.post<any>(this.apiService.baseUrl + "erp/actuacion/delete", activity);
  }

 

  //#region "Pagination"
  buildRowsPerPageList() {
    let rowsPerPageList: PaginationRowsPerPage[] = [];
    let rowCountValList: string[] = ["", "5", "10", "15", "20", "25", "30"];

    let loopIndex: number = -1;

    for (let i = 0; i < rowCountValList.length; i++) {
      var rowPerPageObj = new PaginationRowsPerPage();
      rowPerPageObj.Id = rowCountValList[i];
      rowPerPageObj.RowsPerPage = rowCountValList[i];
      rowsPerPageList.push(rowPerPageObj);
    }
    this.listViewPaginationRowsPerPage$ = this.dropdownService.WrapPaginationRangeToDropDownList(rowsPerPageList, 'RowsPerPage', 'Id');
  }

  buildListViewPaginationRanges(pageNumberList: number[]): void {
    let rangeArr: PaginationRange[] = new Array;
    let paginationRangeValArr: string[] = ["1-100", "101-200", "201-300", "301-400", "401-500",
      "501-600", "601-700", "701-800", "801-900", "901-1000",
      "1001-1100", "1101-1200", "1201-1300", "1301-1400", "1401-1500",
      "1501-1600", "1601-1700", "1701-1800", "1801-1900", "1901-2000",
      "2001-2100", "2101-2200", "2201-2300", "2301-2400", "2401-2500",
      "2501-2600", "2601-2700", "2701-2800", "2801-2900", "2901-3000",
      "3001-3100", "3101-3200", "3201-3300", "3301-3400", "3401-3500",
      "3501-3600", "3601-3700", "3701-3800", "3801-3900", "3901-3000",
      "4001-4100", "4101-4200", "4201-4300", "4301-4400", "4401-4500",
      "4501-4600", "4601-4700", "4701-4800", "4801-4900", "4901-5000",
      "5001-5100", "5101-5200", "5201-5300", "5301-5400", "5401-5500",
      "5501-5600", "5601-5700", "5701-5800", "5801-5900", "5901-6000"
    ];

    for (let i = 0; i < pageNumberList.length; i++) {
      var paginationRangeObj = new PaginationRange();
      paginationRangeObj.PaginationRangeId = i.toString();
      paginationRangeObj.PaginationRangeVal = paginationRangeValArr[i];
      rangeArr.push(paginationRangeObj);
    }
    this.listViewPaginationRanges$ = this.dropdownService.WrapPaginationRangeToDropDownList(rangeArr, 'PaginationRangeVal', 'PaginationRangeId');
  }

  getListViewPaginationRanges(pageNumberList: number[], targetView: string = 'mainlistview'): any[] {
    let rangeArr: PaginationRange[] = new Array;
    let paginationRangeValArr: string[];

    switch (targetView) {
      case "mainlistview":
        paginationRangeValArr = ["1-100", "101-200", "201-300", "301-400", "401-500",
          "501-600", "601-700", "701-800", "801-900", "901-1000",
          "1001-1100", "1101-1200", "1201-1300", "1301-1400", "1401-1500",
          "1501-1600", "1601-1700", "1701-1800", "1801-1900", "1901-2000",
          "2001-2100", "2101-2200", "2201-2300", "2301-2400", "2401-2500",
          "2501-2600", "2601-2700", "2701-2800", "2801-2900", "2901-3000",
          "3001-3100", "3101-3200", "3201-3300", "3301-3400", "3401-3500",
          "3501-3600", "3601-3700", "3701-3800", "3801-3900", "3901-3000",
          "4001-4100", "4101-4200", "4201-4300", "4301-4400", "4401-4500",
          "4501-4600", "4601-4700", "4701-4800", "4801-4900", "4901-5000",
          "5001-5100", "5101-5200", "5201-5300", "5301-5400", "5401-5500",
          "5501-5600", "5601-5700", "5701-5800", "5801-5900", "5901-6000"
        ];
        break;
      case "existingactivities":
        paginationRangeValArr = ["1-50", "51-100", "101-150", "151-200",
          "201-250", "251-300", "301-350", "351-400", "401-450", "451-500",
          "501-550", "551-600", "601-650", "651-700", "701-750", "751-800",
          "801-850", "851-900", "901-950", "951-1000", "1001-1050", "1051-1100",
          "1101-1150", "1151-1200", "1201-1250", "1251-1300", "1301-1350", "1351-1400",
          "1401-1450", "1451-1500", "1501-1550", "1551-1600", "1601-1650", "1651-1700"
        ];
        break;
      default:
        paginationRangeValArr = ["1-100", "101-200", "201-300", "301-400", "401-500",
          "501-600", "601-700", "701-800", "801-900", "901-1000",
          "1001-1100", "1101-1200", "1201-1300", "1301-1400", "1401-1500",
          "1501-1600", "1601-1700", "1701-1800", "1801-1900", "1901-2000",
          "2001-2100", "2101-2200", "2201-2300", "2301-2400", "2401-2500",
          "2501-2600", "2601-2700", "2701-2800", "2801-2900", "2901-3000",
          "3001-3100", "3101-3200", "3201-3300", "3301-3400", "3401-3500",
          "3501-3600", "3601-3700", "3701-3800", "3801-3900", "3901-3000",
          "4001-4100", "4101-4200", "4201-4300", "4301-4400", "4401-4500",
          "4501-4600", "4601-4700", "4701-4800", "4801-4900", "4901-5000",
          "5001-5100", "5101-5200", "5201-5300", "5301-5400", "5401-5500",
          "5501-5600", "5601-5700", "5701-5800", "5801-5900", "5901-6000"
        ];
        break;
    }

    for (let i = 0; i < pageNumberList.length; i++) {
      var paginationRangeObj = new PaginationRange();
      paginationRangeObj.PaginationRangeId = (i + 1).toString();
      paginationRangeObj.PaginationRangeVal = paginationRangeValArr[i];
      rangeArr.push(paginationRangeObj);
    }
    let resultArr = this.dropdownService.WrapPaginationRangeToDropDownList(rangeArr, 'PaginationRangeVal', 'PaginationRangeId');
    return resultArr;
  }
  //#endregion

}
