import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddEditActivityComponent } from '../../popups/add-edit-activity/add-edit-activity.component';
import { AddMultipleActivitiesComponent } from '../../popups/add-multiple-activities/add-multiple-activities.component';
import { ActivityService } from 'src/app/services/activity.service';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import { GAuthService } from 'src/app/services/_authService/gauth.service';
import { LoggedUser } from '../../../../services/_authModel/user';
import { FilterService } from 'src/app/services/filter.service';
import { Router } from '@angular/router';
import { PowerbiService } from 'src/app/services/powerBi/powerbi.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { UserConfigPopupComponent } from '../../popups/user-config-popup/user-config-popup.component';
import { RoleConfigPopupComponent } from '../../popups/role-config-popup/role-config-popup.component';
import { ActionConfigPopupComponent } from '../../popups/action-config-popup/action-config-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  modalOptions: NgbModalOptions;
  public loggedUserInfo: LoggedUser;
  powerBiConfigPanels:any=[];
  isConfiguration : boolean = false;
  isUserVisibale: boolean = false;  
  private currentDate = new Date();

  constructor(
    private modalService: NgbModal,
    private gAuthService: GAuthService,
    private activityService: ActivityService,
    private multipleActivityService: MultipleActivityService,
    private filterService: FilterService,
    private routerService: Router,
    private masterDataService: MasterDataService,
    private powerBiService: PowerbiService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      centered: true,
      size: 'lg'
    };

    this.loggedUserInfo = new LoggedUser();
    this.loggedUserInfo = JSON.parse(localStorage.getItem('loggeduser'));
    this.loggedUserInfo.accessLevel = this.gAuthService.getUserAccessLevelInfo();  }

  ngOnInit() {
    this.registerUserAccessLevel();
    this.getAllPowerBiConfig();
    this.getRolePermission();
  }
 
  onClickUsers(_event) {
    this.modalService.open(UserConfigPopupComponent, this.modalOptions);
  }

  onClickRoles(_event) {
    this.modalService.open(RoleConfigPopupComponent, this.modalOptions);
  }  

  onClickActual() {
    this.modalService.open(ActionConfigPopupComponent, this.modalOptions);
  }

  openAddActivityPopup() {
    const modalRef = this.modalService.open(AddEditActivityComponent, this.modalOptions);
    modalRef.componentInstance.addEditActivityForm.patchValue({
      StatusTypeId: null,
      ActivityStartTime: this.currentDate
    });

    modalRef.result.then((close) => {
      this.filterService.setFilterValuesForAPI();
      this.activityService.filterValues = this.filterService.filterObject;
      this.activityService.fetchActivityList();
    });
  }

  openMultipleActivityPopup() {
    const modalRef = this.modalService.open(AddMultipleActivitiesComponent, this.modalOptions);
    modalRef.result.then((_close) => {
      this.filterService.setFilterValuesForAPI();
      this.activityService.filterValues = this.filterService.filterObject;
      this.activityService.fetchActivityList();
    }, (_dismiss) => {
      this.multipleActivityService.selectedActivity.length = 0;
    });
  }

  logout() {
    this.gAuthService.logout();
    return false;
  }

  private registerUserAccessLevel() {
    this.gAuthService._accessLevelSubject
      .subscribe(res => {
        if (res) {
          this.loggedUserInfo.accessLevel = this.gAuthService.getUserAccessLevelInfo();
        }
      });
  }

  getAllPowerBiConfig(){
    this.powerBiService.getAllConfigPanels().subscribe(res=>{
      this.powerBiConfigPanels=res.filter(x => x.isActive == true);
    })
  }

  powerBiRedirect(url:string,reportId:string,_workSpaceId:string){
    this.routerService.navigate([]).then((_result) => {
      window.open(url+'?reportId='+reportId , '_blank');
    });
  }

  getRolePermission() {
    this.isConfiguration = false;

    let request = {
      UserId: this.loggedUserInfo.id
    }
    this.masterDataService.getRolePermission(request).subscribe(res => {
      if (res) {
        res.forEach(item => {
          if (item.permissions.permisionCode == "SeeConfingButtonAndEnterConfigArea") {
            this.isConfiguration = true;
            return;
          }
        });
      }
    });
  }
}
