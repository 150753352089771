


import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, ViewChild, OnDestroy, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratePdfService } from 'src/app/services/common/generate-pdf.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemoriaAnualService } from 'src/app/services/common/memoria-anual.service';
import moment from 'moment';
import { ActivityFilterModel } from 'src/app/models/activity-filter';
import { FilterService } from 'src/app/services/filter.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { SubSink } from 'subsink';
import { WizardComponent } from 'angular2-wizard';
import { SharedService } from 'src/app/services/shared.service';
import { MemoriaAnualQuiz } from 'src/app/models/memoria-anual-quiz.model';
import { ApiService } from 'src/app/services/api.service';
import { UploadService } from 'src/app/services/upload.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-memoria-anual-popup-with-quiz',
  templateUrl: './memoria-anual-with-quiz-popup.component.html',
  styleUrls: ['./memoria-anual-with-quiz-popup.component.scss']
})
export class MemoriaAnualWithQuizPopupComponent implements OnInit, OnDestroy {
  contracts = [
    { id: 1, name: 'Vilnius-abc' },
    { id: 2, name: 'Kaunas-abc' },
    { id: 3, name: 'Pavilnys-abc', selected: true },
    { id: 4, name: 'Pabradė-abc' },
    { id: 5, name: 'Klaipėda-abc' }
  ];
  @ViewChild("wizardComponent") wizardComponent: WizardComponent;
  @ViewChild("wizardComponentquiz") wizardComponentquiz: WizardComponent;

  @Input() public companyData: any;
  public emailValidators = [Validators.email];
  public emailPlaceholder = 'ingrese la (s) dirección (s) de correo electrónico';
  public emailAddMoreText = '';
  public IsFooterHidden = false;
  period: any = [];
  selectedDate: any;
  reportValues: any;
  ActivityFormGroupWizard1: FormGroup;
  ActivityFormGroupWizard2: FormGroup;
  ActivityFormGroupWizard3: FormGroup;
  ActivityFormGroupWizard4: FormGroup;
  filterObject: ActivityFilterModel;
  public selectedCompanyLanguage = 'es';
  contactPersons: any;
  workCenterData: any;
  actuacionesData: any;
  isCa = false;
  isUpdateInputData = false;
  Implicacion: any;
  activeContracts: any
  selected
  emailAddresses = [];
  private clientReqIPAddress = '';
  base64: any;
  memoriaEmailObject: any;
  private subs = new SubSink();
  public userEmailAddress = '';
  allPoints: number = 15;


  constructor(
    public memoriaAnualModal: NgbActiveModal,
    public fb: FormBuilder,
    private toastrService: ToastrService,
    private pdfService: GeneratePdfService,
    private spinner: NgxSpinnerService,
    private errorHandler: ErrorHandlerService,
    public memoriaAnualService: MemoriaAnualService,
    private filterService: FilterService,
    private sharedService: SharedService,
    private activityService: ActivityService,
    private dropdownService: DropdownService,
    private uploadService: UploadService

  ) {
    this.spinner.hide();
    const loggedUserId = JSON.parse(localStorage.getItem('loggeduser')).id;

    this.subs.add(
      this.dropdownService.getClientReqIPAddress().subscribe(response => {
        this.clientReqIPAddress = response.clientReqIPAddress;
      }, error => {
        this.errorHandler.handle(error);
      }),
      this.dropdownService.getUserEmailAddress(loggedUserId).subscribe(response => {
        this.userEmailAddress = response.userEmail ? response.userEmail : '';
        if (this.userEmailAddress !== '') {
          const emailObj = { display: this.userEmailAddress, value: this.userEmailAddress }
          this.emailAddresses.push(emailObj);
          this.ActivityFormGroupWizard2.get('ContactEmail').setErrors(null);
        }
        this.ActivityFormGroupWizard2.updateValueAndValidity();
      }, error => {
        this.errorHandler.handle(error);
      }),
    );
  }
  ngOnDestroy(): void {  
    this.deleteUploads();
    this.loadQuestions(1);
  }

  DownloadHtmlReport(_id): void {
    this.spinner.show();
    let requestModel = this.setMemoriaValues();
    this.memoriaAnualService.downloadMemoriaWithQuizAttachment(requestModel).subscribe(response => {
      const linkSource = 'data:application/pdf;base64,' + response.base64String + '\n';
      const downloadLink = document.createElement('a');
      const fileName = response.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.spinner.hide();
    }, error => {
      this.toastrService.error('No se pudo descargar el archivo adjunto', 'Error');
      this.errorHandler.handle(error);
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.hide();
    this.pdfService.shareData = this.companyData;
    this.InitFormWizard1();
    this.InitFormWizard2();
    this.getClosedContacts(this.companyData.WorkCentreId);
    this.getWorkCenterData(this.companyData.WorkCentreId);
    this.getContactPersons(this.companyData.WorkCentreId);
    this.getActuaciones(this.companyData.WorkCentreId)

    this.changeWizardText();
    const implicacionValues = {
      Baja: 'Baja',
      Media: 'Media',
      Alta: 'Alta'
    }
    this.Implicacion = implicacionValues; 
    const workCenterId = this.companyData.WorkCentreId;

    this.dropdownService.getContactEmailAddress(workCenterId).subscribe(response => {
      if (response.length > 0) {
        response.forEach(element => {
          const emailObj = { display: element, value: element }
          this.emailAddresses.push(emailObj);
        });
      }
    });

    this.GeneratedFolderName = ((new Date().getTime() * 10000) + 621355968000000000).toString();
  }

  getActuaciones(wordCenterId) {
    let fromStr = this.memoriaAnualService.ActivityFormGroupWizard4.controls.Inicio.value;
    let toStr = this.memoriaAnualService.ActivityFormGroupWizard4.controls.Fin.value;

    if (fromStr != null && toStr != null) {
      const [fromday, frommonth, fromyear] = fromStr.split('/');
      const fromdate = fromyear + frommonth + fromday;

      const [today, tomonth, toyear] = toStr.split('/');
      const todate = toyear + tomonth + today;

      const obj = new ActivityFilterModel();
      obj.fecha_desde = fromdate;
      obj.fecha_hasta = todate;
      obj.id_centro = wordCenterId;
      obj.tipo_consulta = 'TODAS';
      obj.id_empresa = this.companyData.CompanyId;
      obj.id_tecnico = this.filterService.filterObject.id_tecnico;
      obj.id_tecnico_selected = this.filterService.filterObject.id_tecnico_selected;
      obj.id_actuacionestado = null;
      obj.id_actuaciontipo = localStorage.getItem("selectedTipoActuation");
      obj.page_size = Number(localStorage.getItem("selectedRowCount"));
      obj.page_number = 1;

      this.activityService.getFilteredActivities(obj).subscribe(res => {
        this.actuacionesData = res;
      });
    }
  }

  getClosedContacts(wordCenterId) {
    this.contracts = [];
    var obj = {
      workCentreId: wordCenterId,
      activityTypeId: this.companyData.ActivityTypeId
    }
    this.memoriaAnualService.getAllContracts(obj).subscribe(res => {
      if (res.webError != null) {
        this.spinner.hide();
        this.errorHandler.handle(res.webError.messageToUser, true);
      }
      else if (res.length > 0) {
        if(this.companyData.ActivtyTypeName.toLowerCase().includes("envío") &&
        this.companyData.ActivtyTypeName.toLowerCase().includes("entrega"))
        {
          var currentContract = res.filter(x=> { 
            return (new Date(x.dateEndContract) > new Date()) &&
            (new Date(x.dateBeginContract) < new Date()) });
          if(currentContract.length > 0)
          {
            this.selectedDate = '' + moment(new Date(currentContract[0].dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(currentContract[0].dateEndContract)).format('DD/MM/yyyy') + ''
            this.memoriaAnualService.ActivityFormGroupWizard4.patchValue({
              DateRange: Number(currentContract[0].id),
              Inicio: moment(new Date(currentContract[0].dateBeginContract)).format('DD/MM/yyyy'),
              Fin: moment(new Date(currentContract[0].dateEndContract)).format('DD/MM/yyyy')
            });
            
            let obj = {
              id: currentContract[0].id,
              name: moment(new Date(currentContract[0].dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(currentContract[0].dateEndContract)).format('DD/MM/yyyy')
            }
            this.period.push(obj);
            this.contracts = this.period;
            this.getActuaciones(this.companyData.wordCenterId);
          }
        }
        else if (this.companyData.ActivtyTypeName.toLowerCase().includes("elaboración")){
          var closedContracts = res.filter(x=> { 
            return (new Date(x.dateEndContract) < new Date()) });
          if(closedContracts.length > 0)
          {
            this.selectedDate = '' + moment(new Date(closedContracts[0].dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(closedContracts[0].dateEndContract)).format('DD/MM/yyyy') + ''
            this.memoriaAnualService.ActivityFormGroupWizard4.patchValue({
              DateRange: Number(closedContracts[0].id),
              Inicio: moment(new Date(closedContracts[0].dateBeginContract)).format('DD/MM/yyyy'),
              Fin: moment(new Date(closedContracts[0].dateEndContract)).format('DD/MM/yyyy')
            });
            
            closedContracts.forEach(element => {
    
              let obj = {
                id: element.id,
                name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
              }
              this.period.push(obj);
            });
            this.contracts = this.period;
            this.getActuaciones(this.companyData.wordCenterId);
          }
        }

      }
      else {
        this.toastrService.warning("no hay contratos pasados para mostrar");
      }
    })
  }

  getContactPersons(wordCenterId) {
    this.memoriaAnualService.getContactPersons(wordCenterId).subscribe(res => {
      if (res) {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        } else {
          this.contactPersons = res;
        }
      }
    })
  }

  getWorkCenterData(wordCenterId) {
    this.memoriaAnualService.getWorkCenterData(wordCenterId).subscribe(res => {
      if (res) {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        } else {
          this.workCenterData = res;
        }
      }
    })
  }

  InitFormWizard1() {
    this.memoriaAnualService.ActivityFormGroupWizard4 = this.fb.group({
      TemplateLanguage: [this.selectedCompanyLanguage, Validators.required],
      Inicio: [],
      Fin: [],
      TypeOfEmpressa: ["1", Validators.required],
      DateRange: [null]
    });
    this.loadQuestions(1);
  }

  get f() {
    return this.memoriaAnualService.ActivityFormGroupWizard4.controls;
  }

  InitFormWizard2() {
    this.ActivityFormGroupWizard2 = this.fb.group({
      EmailSubject: [null, Validators.required],
      EmailBody: [null, Validators.required],
      ContactEmail: [null, Validators.required]
    });

    this.updateMailBody();
  }

  updateMailBody() {
    if (this.memoriaAnualService.ActivityFormGroupWizard4.value.TemplateLanguage == 'es') {
      this.ActivityFormGroupWizard2.patchValue({
        EmailSubject: 'PDF Memoria anual',
        EmailBody: `Apreciado cliente.\n\n` +
          `Adjunto le remito la “Memoria anual” que recoge las actuaciones llevadas a cabo por GESEME en su empresa, en el último período contractual. \n\n` +
          `Atentamente,`
      });
    } else {
      this.ActivityFormGroupWizard2.patchValue({
        EmailSubject: 'PDF Memoria anual',
        EmailBody: `Apreciado client, \n\n` +
          `Li adjuntem la “Memoria anual” que recull les actuacions realitzades per GESEME en la seva empresa, al llarg de l’últim període contractual. \n\n` +
          `Atentament,`
      });
    }
  }

  onChangePeriod(period) {
    this.memoriaAnualService.ActivityFormGroupWizard4.patchValue({
      Inicio: period.name.split('-')[0],
      Fin: period.name.split('-')[1]
    });

    this.getActuaciones(this.companyData.wordCenterId);
  }

  onChangeLanguage(lang) {
    if (lang.target.value == 'ca') {
      this.isCa = true;
      this.memoriaAnualService.ActivityFormGroupWizard4.patchValue({
        TemplateLanguage: "ca"
      });
    } else {
      this.isCa = false;
      this.memoriaAnualService.ActivityFormGroupWizard4.patchValue({
        TemplateLanguage: "es"
      });
    }
    this.updateMailBody();
  }

  changeWizardText(_isFirma = false): void {
    const modalElement = document.getElementById('modal-annual-report');
    var footer = modalElement.getElementsByClassName('card-footer')[0];
    (<HTMLElement>footer).style.display = 'none';

    if(modalElement.getElementsByClassName('card-footer').length > 1){
      let quizFooter = modalElement.getElementsByClassName('card-footer')[1];
      (<HTMLElement>quizFooter).style.display = 'none';
    }
    const footerBtns = footer.getElementsByClassName('btn');

    for (let index = 0; index < footerBtns.length; index++) {
      footerBtns[index].classList.add('wizard-buttons');
      if (footerBtns[index].innerHTML === 'Previous') {
        footerBtns[index].innerHTML = 'Anterior';
      } else if (footerBtns[index].innerHTML === 'Next') {
        footerBtns[index].innerHTML = 'Siguiente';
      } else if (footerBtns[index].innerHTML === 'Done') {
        footerBtns[index].innerHTML = 'Enviar Mail';
      }
    }
  }

  onNextFistFormWizard() {
    this.isUpdateInputData = true;
  }

  onPreFistFormWizard() {
    this.isUpdateInputData = false;
  }

  setMemoriaValues() {
    let actuacionesTable = [];
    if (this.actuacionesData?.data?.length > 0) {
      actuacionesTable = this.actuacionesData.data.sort((a: any, b: any) => {
        return <any>new Date(b.fecha) - <any>new Date(a.fecha);
      });
    }

    let companyDetails = this.companyData;
    companyDetails.ActivityDate = this.companyData.ActivityDate != null ?
      this.sharedService.dateToString(this.companyData.ActivityDate) : this.companyData.ActivityDate;
    companyDetails.ActivityFinishTime = this.companyData.ActivityFinishTime != null ?
      this.sharedService.dateToString(this.companyData.ActivityFinishTime) : this.companyData.ActivityFinishTime;
    companyDetails.ActivityTime = this.companyData.ActivityTime != null ?
      this.sharedService.dateToString(this.companyData.ActivityTime) : this.companyData.ActivityTime;

    let coverData = {
      language: this.memoriaAnualService.ActivityFormGroupWizard4.value.TemplateLanguage,
      incio: this.memoriaAnualService.ActivityFormGroupWizard4.value.Inicio,
      fin: this.memoriaAnualService.ActivityFormGroupWizard4.value.Fin,
      typeOfEmpressa: this.memoriaAnualService.ActivityFormGroupWizard4.value.TypeOfEmpressa,
    }

    return {
      activityData: coverData,
      companyData: companyDetails,
      contactPersons: this.contactPersons,
      workCenters: this.workCenterData,
      activeContracts: this.activeContracts,
      actuacionesData: actuacionesTable,
      questions : this.Questions,
      anexos: { folder: this.GeneratedFolderName, files: this.FilesList }
    };
  }

  public sendParteDeVisitaEmail(): void {
    this.spinner.show();
    let requestpdf = this.setMemoriaValues();
    if (this.ActivityFormGroupWizard2.valid) {
      this.spinner.show();
      const obj = {
        EmailAddresses: this.emailAddresses,
        EmailSubject: this.ActivityFormGroupWizard2.get('EmailSubject').value,
        EmailBody: this.ActivityFormGroupWizard2.get('EmailBody').value,
        Base64Attachment: null,
        CompanyName: this.pdfService.shareData.CompanyName,
        WorkCentreName: this.pdfService.shareData.WorkCentreName,
        AssignedUserId: this.pdfService.shareData.AssignedUserId
      }

      let reuestObj = {
        memoriaRequestPDF: requestpdf,
        memoriaEmail: obj
      };
      
      this.dropdownService.SendMemoriaEmailWithQuiz(reuestObj).subscribe(res => {
        if (res) {
          if (res == 1) {
            this.toastrService
              .success('Se le enviará un correo electrónico si ha solicitado uno',
                'Éxito');
          } else {
            this.toastrService
              .error('Se produjo un error interno al enviar el correo electrónico',
                'Éxito');
          }
          
          this.loadQuestions(1);
          this.spinner.hide();
          this.memoriaAnualModal.close();
        }
      });
    } else {
      this.ActivityFormGroupWizard2.touched;
      this.spinner.hide();
    }
  }

  checkformvalidation(form: FormGroup){
    return !form.valid;
  }

  complete(){
    this.wizardComponent.complete();
  }




  //#region Quiz Section

  Questions: MemoriaAnualQuiz[] = []
  Step: number = 1;
  SubStep: number = 0;
  Blocks: number[] = [];
  BlocksAnswer: boolean[] = [];
  BlockResult: number[] =[];
  TotalCAnswers: number[] = [];
  TotalQuestionAnswerd: number[] = [];

  loadQuestions(type: number = 1){
    this.Questions = [];
    this.Questions = this.memoriaAnualService.generateMemoriaAnualQuiz(type);
    this.Blocks = this.Questions.map(x=>x.Section).filter(this.distinct);
    this.BlocksAnswer = [];
    this.BlockResult = [];
    this.BlocksAnswer = [];
    this.TotalCAnswers = [];
    this.TotalQuestionAnswerd = [];
  }

  nextStep(){
    if(this.Step == 2){
      if(this.Blocks.length == 1){
        this.wizardComponent.next();
      }
      if(this.SubStep == 6)
      {
        this.Step += 1;
        this.wizardComponent.next();
      }
      else{
        this.SubStep += 1;
        this.wizardComponentquiz.next();
      }
    }
    else {
      this.Step += 1;

      if(this.Step == 2 && this.SubStep == 0)
        this.SubStep += 1;

      this.changeWizardText();
      this.wizardComponent.next();
    }   
    this.createTotalPoints();
  }

  //total points when selecting answers in quiz - memoria-anual
  createTotalPoints(): number {
    let allPoints = 0;

    const calculatePoints = (result: number): number => {
        if (result <= 30) return 0;
        else if (result <= 70) return 1;
        else if (result < 100) return 2;
        else return 3;
    };

    for (let i = 0; i < this.BlockResult.length; i++) {
        const result = this.BlockResult[i];
        let totalPointsBlock = 0;
        
        if (result) {
            totalPointsBlock = calculatePoints(result);
        }

        switch (i) {
            case 0:
                allPoints += totalPointsBlock;
                break;
            case 1:
                allPoints += totalPointsBlock;
                break;
            case 2:
                allPoints += totalPointsBlock;
                break;
            case 3:
                allPoints += totalPointsBlock;
                break;
            case 4:
                allPoints += totalPointsBlock;
                break;
        }
    }

    return allPoints;
}

  prevStep(){
    
    if(this.Step == 2){
      if(this.Blocks.length == 1){
        this.wizardComponent.previous();
      }
      if(this.SubStep == 1)
      {
        this.Step -= 1;
        this.SubStep = 0;
        this.wizardComponent.previous();
      }
      else{
        this.SubStep -= 1;
        this.wizardComponentquiz.previous();
      }
    }
    else {
      this.Step -= 1;

      if(this.Step == 2)
        this.SubStep -= 1;

      this.changeWizardText();
      this.wizardComponent.previous();
    }
  }

  distinct(value, index, array) {
    return array.indexOf(value) === index;
  }

  public checkValidation() {
    //question answer is Nc
    const invalidQuestions = this.Questions.filter(item => item.Answer === 'nc' && (!item.Comment));
    //map ids
    const invalidQuestionIds = invalidQuestions.map(item => item.QuestionId);

    //add classes if invalid
    if (invalidQuestions.length > 0) {
        const textareaElements = document.querySelectorAll('.question-comment');
        if (textareaElements) {
            textareaElements.forEach(item => {
                if (invalidQuestionIds.includes(item.id)) {
                    item.classList.add('invalid-border-color');
                } else {
                    item.classList.remove('invalid-border-color');
                }
            });
        }
        return true;
    } else if (this.SubStep === 6 && this.BlocksAnswer.filter(x => x === true).length === 5) {
        return false;
    } else {
        const textareaElements = document.querySelectorAll('.question-comment');
        if (textareaElements) {
            textareaElements.forEach(item => item.classList.remove('invalid-border-color'));
        }
        return this.BlocksAnswer[this.SubStep - 1] !== true;
    }
}

  public answerQuestion(question: MemoriaAnualQuiz, answer: string){
    question.Answer = answer;

    let validated = true;
    let canswer = 0
    let totalquestions = 0;
    // condition for valacion
    if(this.Step == 2){
      let filteredQuestions = this.Questions.filter(x => {return x.Section == this.SubStep});
      filteredQuestions.forEach(question => {
        if((question.SubQuestions == null || question.SubQuestions.length == 0) && 
        question.Answer == null || question.Answer == ''){
          validated =  false;
        }
        else{
          if(question.Answer == 'c')
            canswer += 1;
          totalquestions += 1;
        }

        if(question.SubQuestions != null){
          let notAnsweredSubquestions = question.SubQuestions.filter( x => {return x.Answer == null || x.Answer == ''});
          if(notAnsweredSubquestions.length > 0 ){          
            validated = false;
          }
          else{
            totalquestions = totalquestions + question.SubQuestions.length;
            canswer = canswer + question.SubQuestions.filter( x => {return x.Answer == 'c'}).length;
          }
        }
      });
      if(validated){
        if(this.SubStep == 2){
          totalquestions = 23;
        }
        this.BlockResult[this.SubStep - 1] =(canswer / totalquestions * 100);
        this.TotalCAnswers[this.SubStep - 1] =(canswer);
        this.TotalQuestionAnswerd[this.SubStep - 1] =(totalquestions);
        this.BlocksAnswer.push(true);
      }
    }
  }

  onStepChanged(){
    var index = this.wizardComponentquiz.activeStepIndex;
    this.SubStep = index + 1;
  }

  aggregate(a, b){
    return a + b;
  }

  //#endregion

  //#region Anexos

  GeneratedFolderName = "";
  FilesList: string[] = [];
  deleteUploads(){
    this.uploadService.deleteAll(this.GeneratedFolderName)
        .subscribe(res => {
        });
  }

  uploadFile(files){
    if (files.length === 0) {
      return;
    }
    let formDataForImages = new FormData();
    formDataForImages.append('folder', this.GeneratedFolderName);
    let fileToUpload = <File>files[0];
    formDataForImages.append('file', fileToUpload, fileToUpload.name);
    
    this.uploadService.uploadimage(formDataForImages)
        .subscribe({
            next: (event) => {
            if (event.type === HttpEventType.UploadProgress){
            }
            else if (event.type === HttpEventType.Response) {
              
              this.FilesList.push(fileToUpload.name);
              this.FilesList = this.FilesList.filter(this.distinct);
            }
          },
          error: (err: HttpErrorResponse) => console.log(err)
        });
  }

  removeFile(file){
    this.uploadService.deleteimage(file, this.GeneratedFolderName)
        .subscribe(res => {
          this.FilesList = this.FilesList.filter(x => x != file);
        });
  }

  //#endregion



}
